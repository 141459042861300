import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import Authentication from "./routes/authentication/authentication.component";
import Shop from "./routes/shop/shop.component";
import Checkout from "./routes/checkout/checkout.component";
import Footer from "./components/footer/footer.component";
import AboutUs from "./routes/About-us/about-us.component";
import UserOrders from "./routes/UserOrders/userOrders.component";
import Admin from "./routes/Admin/admin.component";
import ProductPage from "./routes/product-page/product-page.component";
import TermsConditions from "./routes/Terms&Conditions/Terms&Conditions.component";
import ReturnRefundPolicy from "./routes/Return&RefundPolicy/Return&RefundPolicy.component";
import PrivacyPolicies from "./routes/Privacy-Policies/Privacy-Policies.component";
import PageNotFound from "./routes/PageNotFound/PageNotFound.component";
import ScrollToTop from "./ScrollToTop";
import { checkUserSession } from "./store/user/user.action";
import { GlobalStyle } from "./global.styles";
import Success from "./routes/checkout/stripe-checkout/success.component";
import CookieConsent from "./utils/Cookies/cookies.utils";

import "./App.css";

// Imports para adicionar produtos a base de dados:
//import { addCollectionAndDocuments } from "./utils/firebase/firebase.utils";
//import { SHOP_DATA, BONUS_DATA, BANNERS_DATA } from "./shop-data";

const App = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["cookieConsent"]);
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  // função que adiciona produtos a base de dados  ** categories **
  // useEffect(() => {
  //   addCollectionAndDocuments("categories", SHOP_DATA);
  // }, []);

  // função que adiciona produtos a base de dados  ** bonusProducts **
  // useEffect(() => {
  //   addCollectionAndDocuments("bonusProducts", BONUS_DATA);
  // }, []);

  // função que adiciona produtos a base de dados  ** homeBanner **
  //useEffect(() => {
  //  addCollectionAndDocuments("homeBanner", BANNERS_DATA);
  //}, []);

  return (
    <div className="App">
      <GlobalStyle />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="/shop/*" element={<Shop />} />
          <Route path="/shop/all/:id" element={<ProductPage />} />
          <Route path="/auth" element={<Authentication />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/checkout-success" element={<Success />} />
          {/* <Route
            path="/orders"
            element={currentUser ? <UserOrders /> : <Navigate to="/" />}
          />
          <Route
            path="/admin"
            element={
              currentUser?.isAdmin || false ? <Admin /> : <Navigate to="/" />
            }
          /> */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/Terms&Conditions" element={<TermsConditions />} />
          <Route path="/Return&RefundPolicy" element={<ReturnRefundPolicy />} />
          <Route path="/PrivacyPolicies" element={<PrivacyPolicies />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
      {!cookies.cookieConsent && <CookieConsent />}
      <Footer />
    </div>
  );
};

export default App;
