import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { collection, getDocs } from "firebase/firestore";

import { selectCategoriesMap } from "../../store/categories/category.selector";
import Directory from "../../components/directory/directory.component";
import HighlightedProducts from "../../components/highlighted-product/highlighted-product.component";
import HomeButtons from "../../components/home-buttons/home-buttons.component";
import Spinner from "../../components/spinner/spinner.component";

import { db } from "../../utils/firebase/firebase.utils";
import { fetchCategoriesStart } from "../../store/categories/category.action";

import "./home.styles.scss";

const Home = () => {
  const dispatch = useDispatch();
  const [highlightedProductIds, setHighlightedProductIds] = useState([]);
  const [recentProducts, setRecentProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastDropTitle, setLastDropTitle] = useState("");
  const categoriesMap = useSelector(selectCategoriesMap);
  const productsToShow = categoriesMap["all"] || [];

  useEffect(() => {
    dispatch(fetchCategoriesStart());
  }, [dispatch]);

  useEffect(() => {
    const fetchHighlightedProductIds = async () => {
      try {
        const highlightedProductsCollection = collection(
          db,
          "highlightedProducts"
        );
        const highlightedProductsSnapshot = await getDocs(
          highlightedProductsCollection
        );
        const productIds = highlightedProductsSnapshot.docs.map(
          (doc) => doc.data().productId
        );

        setHighlightedProductIds(productIds);
      } catch (error) {
        console.error("Erro ao buscar IDs de produtos destacados:", error);
      }
    };

    const fetchRecentProducts = async () => {
      try {
        const dropsCollection = collection(db, "categories");
        const dropsSnapshot = await getDocs(dropsCollection);
        const drops = dropsSnapshot.docs.map((doc) => ({
          title: doc.data().title.toLowerCase(),
          products: doc.data().items || [],
        }));

        const getDropNumber = (dropTitle) => {
          const match = dropTitle.match(/(\d+)[ºª]/);
          return match ? parseInt(match[1]) : 0;
        };

        const sortedDrops = drops.sort(
          (a, b) => getDropNumber(b.title) - getDropNumber(a.title)
        );

        let collectedProducts = [];
        let titleOfLastDrop = sortedDrops[0].title;

        for (const drop of sortedDrops) {
          collectedProducts = collectedProducts.concat(drop.products);
          if (collectedProducts.length >= 4) {
            break;
          }
        }

        setRecentProducts(collectedProducts.slice(0, 4));
        setLastDropTitle(titleOfLastDrop);
      } catch (error) {
        console.error("Erro ao buscar produtos recentes:", error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await fetchHighlightedProductIds();
      await fetchRecentProducts();
      setLoading(false);
    };

    if (Object.keys(categoriesMap).length > 0) {
      fetchData();
    }
  }, [categoriesMap]);

  if (loading) {
    return <Spinner />;
  }

  const highlightedProducts = productsToShow.filter((product) =>
    highlightedProductIds.includes(product.id)
  );

  return (
    <div>
      <div className="home-container">
        <div className="home-homeButtons">
          <HomeButtons />
        </div>
        <div>
          <Directory />
          <Outlet />
          {highlightedProducts.length > 0 && (
            <HighlightedProducts
              title="Highlighted products"
              redirectLink="/shop/all"
              products={highlightedProducts}
            />
          )}
          {recentProducts.length > 0 && (
            <HighlightedProducts
              title="New In"
              redirectLink={`/shop/${lastDropTitle}`}
              products={recentProducts}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
