import { Link } from "react-router-dom";

import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaCcVisa,
  FaCcMastercard,
} from "react-icons/fa";
import { RiMailAddLine } from "react-icons/ri";

import "./footer.styles.scss";

const Footer = () => {
  return (
    <div className="footer">
      <ul className="social_list">
        <li>
          <a href="https://www.facebook.com/profile.php?id=100085393163270">
            <FaFacebook />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/eme.strong/">
            <FaInstagram />
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@eme.strong?_t=8aAgxJNAXGY&_r=1">
            <FaTiktok />
          </a>
        </li>
      </ul>
      <a className="TeamMail" href="mailto:support@emestrong.pt">
        Become one of us... <RiMailAddLine />
      </a>
      <div className="PaymentCardsContainer">
        <FaCcVisa style={{ color: "#1434CB" }} />
        <FaCcMastercard style={{ color: "#FF5F00" }} />
      </div>
      <div className="footer-bottom">
        <div className="footer-links">
          <Link to="/Terms&Conditions" className="To-TermsConditions">
            Terms and Conditions
          </Link>
          <Link to="/Return&RefundPolicy" className="To-TermsConditions">
            Returns Policy
          </Link>
        </div>
        <div className="footer-rights">
          <p className="copy_right">
            <span>Eme Strong</span> &copy; 2023
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
