import "../About-us/about-us.styles.scss";
import Group from "../About-us/About-Us_Image.png";

const AboutUs = () => {
  return (
    <div className="container-about">
      <span>
        <p>
          A Eme Strong é uma marca de moda fitness, orgulhosamente criada em
          Portugal. Nosso foco primordial é a criação de vestuário de qualidade
          para os entusiastas do ginásio. Procuramos apoiar tanto os atletas
          dedicados, como inspirar aqueles que estão considerando começar,
          oferecendo estilo e conforto incomparáveis.
        </p>
        Comprometemo-nos a servir um público jovem e ambicioso, altamente
        motivado a aprimorar suas performances esportivas. A equipe da Eme
        Strong é predominantemente formada por jovens talentos, contando com
        dezenas, incluindo atletas e colaboradores apaixonados. Dentro da nossa
        marca, promovemos vários projetos destinados aos nossos clientes, como
        iniciativas de vídeos interativos, bem como eventos presenciais em
        diversas regiões de Portugal. Além disso, temos projetos direcionados
        para o desenvolvimento contínuo da nossa empresa, incluindo a integração
        de estudantes universitários em áreas específicas.
        <p>
          Está interessado em se juntar a nós como colaborador ou em participar
          de algum dos nossos emocionantes projetos? Por favor, não hesite em
          entrar em contato connosco através do
          <a href="mailto:support@emestrong.pt">support@emestrong.pt</a>.
        </p>
      </span>
      {/* <img src={Group} alt="Imagem" /> */}
    </div>
  );
};

export default AboutUs;
