import { useSelector } from "react-redux";
import { useState, useMemo } from "react";

import {
  selectCartItems,
  selectCartTotal,
} from "../../store/cart/cart.selector";
import CheckoutItem from "../../components/checkout-item/checkout-item.component";
import StripeCheckout from "./stripe-checkout/stripe-checkout";
import { selectCategoriesMap } from "../../store/categories/category.selector";

import "./checkout.styles.scss";
import BonusProducts from "../../components/bonus-products/bonus-products.component";

const Checkout = () => {
  const categoriesMap = useSelector(selectCategoriesMap);
  const productsToShow = categoriesMap["all"];

  const cartData = useSelector((state) => ({
    cartItems: selectCartItems(state),
    cartTotal: selectCartTotal(state),
  }));

  const [selectedCountry, setSelectedCountry] = useState("PT");

  const countryData = useMemo(
    () => ({
      AT: "Austria (AT)",
      BE: "Belgium (BE)",
      BG: "Bulgaria (BG)",
      CY: "Cyprus (CY)",
      CZ: "Czech Republic (CZ)",
      DE: "Germany (DE)",
      DK: "Denmark (DK)",
      EE: "Estonia (EE)",
      ES: "Spain (ES)",
      FI: "Finland (FI)",
      FR: "France (FR)",
      GR: "Greece (GR)",
      HR: "Croatia (HR)",
      HU: "Hungary (HU)",
      IE: "Ireland (IE)",
      IT: "Italy (IT)",
      LT: "Lithuania (LT)",
      LU: "Luxembourg (LU)",
      LV: "Latvia (LV)",
      MT: "Malta (MT)",
      NL: "Netherlands (NL)",
      PL: "Poland (PL)",
      PT: "Portugal (PT)",
      RO: "Romania (RO)",
      SE: "Sweden (SE)",
      SI: "Slovenia (SI)",
      SK: "Slovakia (SK)",
    }),
    []
  );

  function formatCurrency(amount) {
    return Number(amount).toFixed(2);
  }

  const bonusAmountNeeded = false; /* useMemo(
    () => 40 - cartData.cartTotal,
    [cartData.cartTotal]
  ); */
  const bonusPercentage = useMemo(
    () => ((40 - bonusAmountNeeded) / 40) * 100,
    [bonusAmountNeeded]
  );
  const shouldRenderBonusProducts = false; /* useMemo(
    () =>
      bonusAmountNeeded <= 0 &&
      !cartData.cartItems.some((item) => item.newPrice === "0.00"),
    [bonusAmountNeeded, cartData.cartItems]
  ); */

  return (
    <>
      <div className="checkout-container">
        <div className="bonus-bar">
          {bonusAmountNeeded > 0 && (
            <>
              <h2>Free product</h2>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${bonusPercentage}%` }}
                ></div>
              </div>
              <p>{formatCurrency(bonusAmountNeeded)}€ left to reach 40€!</p>
            </>
          )}
        </div>
        <div className="checkout-header">
          <div className="header-block">
            <span>Product</span>
          </div>
          <div className="header-block">
            <span>Description</span>
          </div>
          <div className="header-block">
            <span>Quantity</span>
          </div>
          <div className="header-block">
            <span>Price</span>
          </div>
          <div className="header-block">
            <span>Remove</span>
          </div>
        </div>
        {cartData.cartItems.map((cartItem) => (
          <CheckoutItem key={cartItem.colorCode} cartItem={cartItem} />
        ))}
        <span className="total">
          Total: {formatCurrency(cartData.cartTotal)}€
        </span>
        <div className="country-select">
          <label htmlFor="country" className="form-label">
            Select Country:
          </label>
          <select
            id="country"
            name="country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            className="form-select"
          >
            {Object.keys(countryData).map((countryCode) => (
              <option key={countryCode} value={countryCode}>
                {countryData[countryCode]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="checkout-botton-container">
        {shouldRenderBonusProducts && productsToShow && (
          <BonusProducts products={productsToShow} />
        )}
        <StripeCheckout selectedCountry={selectedCountry} />
      </div>
    </>
  );
};

export default Checkout;
